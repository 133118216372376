'use client'
import {useContext, useEffect, useRef, useState} from "react";
import CartPopUpModal from "@/app/components/common/CartPopUpModal";
import {useCartHook} from "@/app/hooks/useCartHook";
import {useRouter} from "next/navigation";
import CartModalContext from "@/app/context/CartModalContext";
import {getCart} from "@/app/services/CartService";
import Link from "next/link";

const HeaderMobile = ({data}) => {

    const [menuOpen, setMenuOpen] = useState(false)
    const {isCartModalOpen, setIsCartModalOpen, reload, setReload} = useContext(CartModalContext)
    const menuRef = useRef(null);
    const { cart } = useCartHook({ cookieData: data.cookieCart });
    const [cartProductCount, setCartProductCount] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const router = useRouter()

    const cartProductIds = Object.keys(cart);
    const productsInCart = data?.products.filter(product =>
        cartProductIds.includes(product.id.toString()));

    useEffect(() => {
        let timer;
        if (menuOpen) {
            timer = setTimeout(() => setIsAnimating(true), 10);
        } else {
            setIsAnimating(false);
        }
        return () => clearTimeout(timer);
    }, [menuOpen]);


    useEffect(() => {
        getCart().then(response => {
            if (response){
                const ids = Object.keys(response)
                if (ids.length === 0) {
                    setCartProductCount(0)
                    return
                }
                if (ids.length) {
                    setCartProductCount(ids.length)
                }
            }
        })
    }, [cart, reload]);


    // useEffect(() => {
    //     const fetchCart = async () => {
    //         try {
    //             const response = await getCart();
    //             if (response) {
    //                 const ids = Object.keys(response);
    //                 if (ids.length === 0) {
    //                     setCartProductCount(0);
    //                     return;
    //                 }
    //                 setCartProductCount(ids.length);
    //             }
    //         } catch (error) {
    //             console.error('Failed to fetch cart:', error);
    //         }
    //     };
    //
    //     fetchCart();
    // }, [cart, reload]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuOpen && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [menuOpen]);

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    }


    const calculateDiscount = (newProduct, cart) => {
        const sum = Object.values(cart).reduce((acc, currentValue) => {
            return acc + currentValue[0].quantity;
        }, 0);
        const totalPrice = calculateSubtotal(newProduct, cart)
        if(sum === 2){
            return totalPrice * 5/100
        } else if(2 < sum && sum <= 4){
            return totalPrice * 10/100
        } else if(sum >= 5){
            return totalPrice * 15/100
        }else
            return 0
    }


    function calculateSubtotal(newProducts, cart) {
        return newProducts.reduce((acc, product) => {
            const quantity = cart[product.id][0]?.quantity || 0;
            const price = product?.price || 0;
            return acc + (price * quantity);
        }, 0);
    }

    const calculateTotal = () => {
        return productsInCart.reduce((total, product) => {
            return total + (product.price * (cart[product.id] ? cart[product.id][0]?.quantity : 1));
        }, 0);
    }

    const real = () => {
        return calculateTotal()-calculateDiscount(data.products.filter(product => cart[product.id]), cart)
    }

    const openModal = () => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'view_cart',
        value: real() || parseFloat(0),
        ecommerce: {
            currency: "RSD",
            items: productsInCart.map(item => ({
                item_id: item.id || -1,
                item_name: item.name || " ",
                category: item?.categories[0]?.name || 'Nedefinisano',
                variant: item.variant || 'Nedefinisano',
                price: item.price || 0,
                quantity: cart[item.id] ? cart[item.id][0]?.quantity : 1
            }))
        }
    });
    setIsCartModalOpen(true);
    };

    const closeModal = () => {
        setIsCartModalOpen(false);
    };

    const handleAswClick = () => {
        router.push('/crux-ashwagandha');
        setMenuOpen(false);
    };

    const handleCollagenClick = () => {
        router.push('/crux-collagen');
        setMenuOpen(false);
    };

    return (
        <div ref={menuRef} className={`fixed h-[75px] top-0 left-0 w-full z-[990] ${menuOpen ? 'bg-cruxBeige md:bg-white' : 'bg-cruxBeige'}  shadow-md`}>
            <div className='flex flex-row justify-between items-center px-[46px] pt-[20px] pb-[20px]'>
                <button onClick={handleMenuToggle} className='p-2'>
                    <img src={'/menu.svg'} alt="Menu" className='w-[24px] h-[24px]'/>
                </button>
                <a href='/crux-ashwagandha'>
                    <img src='/header-logo.svg' alt='Logo' className='w-[32px] h-[33px]' />
                </a>
                <button className='p-2'>
                    <img src={'/cart-gold.svg'} alt="Cart" className='w-[24px] h-[24px]' onClick={openModal}/>
                    {cartProductCount !== 0 ?
                        <span id="cartItemCount" className=" absolute top-[16px] right-[35px] bg-cruxGreen text-white rounded-full px-[7px] py-[2px] text-xs">{cartProductCount}</span>
                        :
                        null
                    }
                </button>
            </div>
            {menuOpen && (
                <div  className={`fixed pt-[25%] top-0 left-0 min-h-screen w-3/4 max-w-sm px-4 bg-[#F7F4ED] rounded-tr-[15%] shadow-lg flex flex-col items-center ${isAnimating ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"} transition-all duration-500 ease-in-out`}>
                    <img src={'/crux.png'} alt="Crux" className='mb-[20%] mt-[0px] w-[150px]'/>
                    <div className={'flex flex-col gap-2.5 relative w-[300px]  ml-[100px] my-[30px]'}>
                        <div onClick={handleCollagenClick} className={'flex justify-between bg-white h-[110px] rounded-[5px] px-[20px] py-[10px] gap-3.5'}>
                            <img width={94} height={94} src={'/new-coll.png'}/>
                            <div className={'flex flex-col  justify-center items-center  w-[157px]'}>
                                <div className={'text-[14px] font-[400] leading-[16px] tracking-[4.2px] text-center text-[#5B4841] mb-[5px]'}>COLLAGEN <br/> PEPTIDES</div>
                                <button className={'bg-[#F7F4ED] text-cruxBrown text-[10px] font-[400] tracking-[1px] rounded-[30px] px-[20px] py-[8px]'}>PROČITAJ VIŠE</button>
                            </div>
                        </div>
                        <div onClick={handleAswClick} className={'flex justify-between bg-white h-[110px] rounded-[5px] p-[20px] gap-3.5'}>
                            <img className={'ml-[10px]'} width={70} height={94} src={'/cruxBottle.png'}/>
                            <div className={'flex flex-col justify-center items-center w-[157px]'}>
                                <p className='text-[14px] font-[400] leading-[16px] tracking-[4.2px] text-center text-[#5B4841] mb-[5px]'>CRUX <br/> ASHWAGANDHA</p>
                                <button className={'bg-[#F7F4ED] text-cruxBrown text-[10px] font-[400] tracking-[1px] rounded-[30px] px-[20px] py-[8px]'}>PROČITAJ VIŠE</button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between text-center space-y-[30px]">
                        <Link href="/blog" onClick={() => setMenuOpen(false)} className="text-[14px] font-[300] leading-[16px] tracking-[4.2px] text-[#8F7E76] font-semibold block w-full text-center">BLOG</Link>
                        <Link href="/prodavnica" onClick={() => setMenuOpen(false)} className="text-[14px] font-[300] leading-[16px] tracking-[4.2px] text-[#8F7E76] font-semibold block w-full text-center">PRODAVNICA</Link>
                        <Link href="/utisci" onClick={() => setMenuOpen(false)} className="text-[14px] font-[300] leading-[16px] tracking-[4.2px] text-[#8F7E76] font-semibold block w-full text-center">ISKUSTVA</Link>
                        <Link href="/kontakt" onClick={() => setMenuOpen(false)} className="text-[14px] font-[300] leading-[16px] tracking-[4.2px] text-[#8F7E76] font-semibold block w-full text-center">KONTAKT</Link>
                    </div>
                    <img src={'/header-logo.svg'} alt="Logo" className='mt-[52px] w-[32px]'/>
                </div>
            )}
            <CartPopUpModal setCartProductCount={setCartProductCount} setReload={setReload} reload={reload} isOpen={isCartModalOpen} setIsCartModalOpen={setIsCartModalOpen} onClose={closeModal} data={data}/>
        </div>
    )

}
export default HeaderMobile;
